import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LearningObjectStatus } from 'src/constants/learningObject';
import { searchContents } from 'src/services/requests/learningObjects';
import { LearningTypes } from 'src/types/LearningTypes';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { LearningObjectResponse } from 'src/types/requests/LearningObjects';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { SearchRequestBody } from 'src/types/validators/SearchRequestBody';

import { LearningObjectsState } from './types';
import type { RootState } from '../store';

export const searchLearningObjects = createAsyncThunk(
  'learningObjects/search',
  async (
    { keyword, status }: { keyword: string, status?: LearningObjectStatus[] },
    { rejectWithValue, getState },
  ) => {
    const { auth, user, learningObjects } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('Learning objects search: User is not logged in');
    }

    const offset = (learningObjects.currentPage - 1) * learningObjects.pageSize;
    const organizationIdsStr = user.organizationIds.join(',');
    const queryParams = {
      size: learningObjects.pageSize,
      offset,
      organizationIds: organizationIdsStr,
    };

    const searchOptions: SearchRequestBody = {
      query: keyword || '',
      types: [LearningTypes.TRAINING],
    };
    if (status) {
      searchOptions.metadata = { status };
    }

    type Response = FlowReturn<typeof searchContents>;
    const response: Response = await searchContents(auth.tokens.accessToken, searchOptions, queryParams);

    if (!response || !Array.isArray(response.items)) {
      return rejectWithValue('Learning objects search: Failed trying to search for learning objects');
    }

    const { items, count } = response;
    const totalResultCount = count || 0;
    const pageCount = Math.ceil(totalResultCount / learningObjects.pageSize);

    return { items, totalResultCount, pageCount };
  },
);

export const searchCaseHandlers: ThunkCaseHandlers<LearningObjectsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.learningObjects = [];
  },
  handleFulfilled: (state, action: PayloadAction<{
    items: LearningObjectResponse[],
    totalResultCount: number,
    pageCount: number,
  }>) => {
    state.learningObjects = action.payload.items;
    state.totalResultCount = action.payload.totalResultCount;
    state.pageCount = action.payload.pageCount;
    state.status = Status.SUCCEEDED;
    state.loContentsFetched = true;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
    state.showApiErrDialog = true;
  },
};
