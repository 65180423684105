import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultPrimaryColor, defaultSecondaryColor } from 'src/config/theme/theme';

type ThemeState = {
  primaryColor: string,
  secondaryColor: string
};

export const initialState: ThemeState = {
  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setPrimaryColor(state, { payload }: PayloadAction<string>) {
      state.primaryColor = payload;
    },
    setSecondaryColor(state, { payload }: PayloadAction<string>) {
      state.secondaryColor = payload;
    },
  },
});

export default themeSlice.reducer;

export const { setPrimaryColor, setSecondaryColor } = themeSlice.actions;
