import { useState } from 'react';

import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlertDialog from 'src/components/@common/AlertDialog/AlertDialog';
import { ROUTES, reverseRoute } from 'src/config/routes/routes';

function LogOutMenuItem({ closeMenu }: { closeMenu: () => void }) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const handleClickOpen = () => setOpenAlertDialog(true);

  const handleLogout = () => {
    setOpenAlertDialog(false);
    closeMenu();
    navigate(reverseRoute(ROUTES.logout));
  };

  const handleCancel = () => setOpenAlertDialog(false);

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>

        <ListItemText>{t('profileMenu.logOut')}</ListItemText>
      </MenuItem>

      {
        openAlertDialog && (
        <AlertDialog
          title={t('logOutDialog.title')}
          description={t('logOutDialog.description')}
          cancelButtonText={t('logOutDialog.cancel')}
          onCancelPress={handleCancel}
          confirmButtonText={t('logOutDialog.logOut')}
          onConfirmPress={handleLogout}
          data-testid="LogOutMenuItem-Dialog-test-id"
        />
        )
      }
    </>
  );
}

export default LogOutMenuItem;
