import {
  generateApiUrl,
  TP_PATH_PATTERNS,
} from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';
import { TrainingProgramRequest } from 'src/types/validators/TrainingProgramRequest';
import {
  TrainingProgramResponse,
  validateTrainingProgramResponse,
} from 'src/types/validators/TrainingProgramResponse';

export async function getProgram(
  programId: string,
  accessToken: string,
): Promise<TrainingProgramResponse | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.PROGRAM, { programId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Program get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Program get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Program get request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json as TrainingProgramResponse;
}

export async function postTrainingProgram(
  programData: TrainingProgramRequest,
  accessToken: string,
): Promise<TrainingProgramResponse | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.ROOT);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(programData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`Program post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Program post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Program post request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateTrainingProgramResponse(json)) {
    return json;
  }

  console.error('Program post response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

export async function putTrainingProgram(
  programId: string,
  programData: TrainingProgramRequest,
  accessToken: string,
): Promise<TrainingProgramResponse | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.PROGRAM, { programId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'PUT',
    body: JSON.stringify(programData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Program put request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Program put request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Program put request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  if (validateTrainingProgramResponse(json)) {
    return json;
  }

  console.error('Program put response structure is invalid. Probably API has changed', getValidationErrors(), json);
  return undefined;
}

export async function deleteTrainingProgram(
  programId: string,
  accessToken: string,
): Promise<boolean> {
  const route = generateApiUrl(TP_PATH_PATTERNS.PROGRAM, { programId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 204) {
    console.error(`Program delete request failed with status ${response.status}`, response);

    return false;
  }

  return true;
}
