import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { searchCaseHandlers, searchTrackings } from './thunks';
import { TrackingSearchState } from './types';

export const initialState: TrackingSearchState = {
  showApiErrDialog: false,
  status: Status.IDLE,
  trackings: [],
};

const trackingSearchSlice = createSlice({
  name: 'trackingSearch',
  initialState,
  reducers: {
    resetTrackingSearchStore: (state) => {
      state.showApiErrDialog = false;
      state.status = Status.IDLE;
      state.trackings = [];
    },
    setShowApiErrDialog: (state, action) => {
      state.showApiErrDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // search
      .addCase(searchTrackings.pending, searchCaseHandlers.handlePending)
      .addCase(searchTrackings.fulfilled, searchCaseHandlers.handleFulfilled)
      .addCase(searchTrackings.rejected, searchCaseHandlers.handleRejected);
  },
});

export const { resetTrackingSearchStore, setShowApiErrDialog } = trackingSearchSlice.actions;

export default trackingSearchSlice.reducer;
