import {
  Suspense, useEffect, useMemo,
} from 'react';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import {
  BrowserRouter, useNavigate, useMatch, useLocation,
} from 'react-router-dom';

import BackdropLoading from './components/@common/BackdropLoading/BackdropLoading';
import MainHeader from './components/@common/MainHeader/MainHeader';
import SessionTimer from './components/@common/SessionTimer/SessionTimer';
import Router from './config/routes/Router';
import { ROUTES, reverseRoute } from './config/routes/routes';
import { createPlatformTheme, defaultPrimaryColor, defaultSecondaryColor } from './config/theme/theme';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { store as reduxStore } from './redux/store';
import { setPrimaryColor, setSecondaryColor } from './redux/theme/slice';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthPage = useMatch(reverseRoute(ROUTES.auth));
  const isCklsAuthPage = useMatch(reverseRoute(ROUTES.cklsAuth));

  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const themeStore = useAppSelector((state) => state.theme);
  const platformDataStyle = useAppSelector((state) => state.user.platformData?.platformDataStyle);

  useEffect(() => {
    dispatch(setPrimaryColor(defaultPrimaryColor));
    dispatch(setSecondaryColor(defaultSecondaryColor));

    if (!platformDataStyle) return;

    const { primaryColor, secondaryColor } = platformDataStyle;

    if (primaryColor) dispatch(setPrimaryColor(primaryColor));
    if (secondaryColor) dispatch(setSecondaryColor(secondaryColor));
  }, [dispatch, platformDataStyle]);

  const theme = useMemo(
    () => createPlatformTheme(themeStore.primaryColor, themeStore.secondaryColor),
    [themeStore.primaryColor, themeStore.secondaryColor],
  );

  useEffect(() => {
    // INITIALIZE AUTH
    if (!auth.initialized && !isCklsAuthPage && !isAuthPage) {
      navigate(reverseRoute(ROUTES.auth), {
        state: { redirectRoute: location.pathname },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Suspense: here app catches the suspense from page in case translations are not yet loaded
  return (
    <Suspense fallback={<BackdropLoading />}>
      <CssBaseline />

      <ThemeProvider theme={theme}>
        <div>
          {!isCklsAuthPage && (
            <MainHeader />
          )}

          {auth.tokens ? (
            <SessionTimer />
          ) : null}

          <Router />
        </div>
      </ThemeProvider>
    </Suspense>
  );
};

export default function WrappedApp() {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}
