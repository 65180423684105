import { generateApiUrl, TRACKING_PATH_PATTERNS } from 'src/constants/api';
import { getValidationErrors } from 'src/types/validators/ajv';
import { TrackingSearchRequest } from 'src/types/validators/TrackingSearchRequest';
import { TrackingSearchResponse, validateTrackingSearchResponse } from 'src/types/validators/TrackingSearchResponse';

export async function searchTrackings(
  accessToken: string,
  requestBody: TrackingSearchRequest,
) {
  const route = generateApiUrl(TRACKING_PATH_PATTERNS.SEARCH);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers,
  };

  let response: Response;
  try {
    response = await fetch(route, options);
  } catch (e) {
    console.error('Unexpected search fetch error', e);
    return;
  }

  let json: any;
  try {
    json = response.json();
  } catch (e) {
    console.error('Unable to serialize search result', e);
    return;
  }

  if (process.env.NODE_ENV === 'development' && !validateTrackingSearchResponse(json)) {
    console.warn(
      'Tracking search result structure is invalid. Probably api has changed.',
      getValidationErrors(),
      json,
    );
  }

  return json as TrackingSearchResponse;
}
