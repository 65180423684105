import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getSingleOrganization } from 'src/services/requests/organizations';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { SingleOrganizationResponse } from 'src/types/requests/Organizations';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import { OrganizationState } from './types';
import type { RootState } from '../store';

// Get a single organization
export const fetchOrganization = createAsyncThunk(
  'organization/fetchOrganization',
  async (_, { rejectWithValue, getState }) => {
    const { auth, user } = getState() as RootState;

    const accessToken = auth.tokens?.accessToken;
    if (!accessToken) return rejectWithValue('fetchOrganization: User is not logged in');

    const organizationId = user.organizationIds[0].toString();
    if (!organizationId) return rejectWithValue('fetchOrganization: No organization found');

    try {
      type OrganizationResponse = FlowReturn<typeof getSingleOrganization>;
      const response: OrganizationResponse = await getSingleOrganization(
        organizationId,
        accessToken,
      );

      if (!response) return rejectWithValue('fetchOrganization: Could not fetch the organization');

      return response;
    } catch (err: any) {
      console.error(err.message);
    }
  },
);

export const fetchOrganizationCaseHandlers: ThunkCaseHandlers<OrganizationState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<SingleOrganizationResponse>) => {
    state.organization = payload;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};
