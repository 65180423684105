import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { searchCaseHandlers, searchLearningObjects } from './thunks';
import { LearningObjectsState } from './types';

const initialState: LearningObjectsState = {
  currentPage: 1,
  learningObjects: [],
  loContentsFetched: false,
  pageSize: 4,
  pageCount: 0,
  showApiErrDialog: false,
  status: Status.IDLE,
};

const learningObjectsSlice = createSlice({
  name: 'learningObjects',
  initialState,
  reducers: {
    resetLearningObjectsStore: (state) => {
      state.learningObjects = [];
      state.currentPage = 1;
      state.totalResultCount = 0;
      state.loContentsFetched = false;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setShowApiErrDialog: (state, action) => {
      state.showApiErrDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // search
      .addCase(searchLearningObjects.pending, searchCaseHandlers.handlePending)
      .addCase(searchLearningObjects.fulfilled, searchCaseHandlers.handleFulfilled)
      .addCase(searchLearningObjects.rejected, searchCaseHandlers.handleRejected);
  },
});

export const {
  resetLearningObjectsStore,
  setCurrentPage,
  setShowApiErrDialog,
} = learningObjectsSlice.actions;

export default learningObjectsSlice.reducer;
