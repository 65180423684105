import ConfigClient from '../config/ConfigClient';

interface Metadata {
  [key: string]: string | number | boolean | string[];
}

class MeasurementsClient {
  /**
   * Workaround to wait until Pendo Guide (survey) will finish
   * since there is no corresponding API in Pendo
   * Perhaps we need to avoid call Pendo inside the code
   * because if we have some changes for Pendo we will likely need to release new application
   * instead of change settings in Pendo system online
   */
  static awaitPendoGuideIsCompleted() {
    return new Promise <void>((resolve) => {
      const handler = setInterval(() => {
        if (!(pendo as any).getActiveGuide()) {
          resolve();
          clearInterval(handler);
        }
      }, 500);
    });
  }

  /**
  * init
  */
  static init(visitorId: string, accountId: string): void {
    if (!!ConfigClient.get('PENDO_API_KEY') && Object.hasOwn(window, 'pendo')) {
      pendo.initialize({
        visitor: {
          id: visitorId, // Required if user is logged in, default creates anonymous ID
          // email: // Recommended if using Pendo Feedback, or NPS Email
          // full_name: // Recommended if using Pendo Feedback
          // role: // Optional
          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        } as pendo.IdentityMetadata, // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/61415
        account: {
          id: accountId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          // name: // Optional
          // is_paying: // Recommended if using Pendo Feedback
          // monthly_value: // Recommended if using Pendo Feedback
          // planLevel: // Optional
          // planPrice: // Optional
          // creationDate: // Optional
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    }
  }

  /**
   * track
   */
  static track(trackType: string, metadata?: Metadata): void {
    if (pendo.isReady()) {
      pendo.track(trackType, metadata);
    }
  }
}

export default MeasurementsClient;
