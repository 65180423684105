import jwt_decode from 'jwt-decode';

interface DecodedToken {
  ace: string,
  aud: string,
  azp: string,
  email: string,
  email_verified: boolean,
  exp: number,
  iat: number,
  iss: string,
  jti: string,
  pendoUserUuid: string,
  keycloakId: string,
  preferred_username: string,
  realm_access: {
    roles: string[],
  },
  resource_access: {
    account: string[],
  },
  scope: string,
  session_state: string,
  sid: string,
  user_id: string,
  sub: string,
  typ: string,
}

export function decodeToken(token: string): Partial<DecodedToken> {
  return jwt_decode(token);
}

/**
 * Returns the remaining time for the token to expire, in seconds, considering
 * the token expiration time (the `exp` field in the decoded token) as a parameter.
 */
export function getTimeToExpire(expirationTime: number) {
  const timeLocal = Math.round(new Date().getTime() / 1000);
  return expirationTime - timeLocal;
}

/**
 * Returns true if token is expired.
 * 
 * Considers a token that remains 10 seconds to expire as expired.
 * Takes the token expiration time as a parameter (`exp` field in decoded token).
 */
export function isTokenExpired(expirationTime: number): boolean {
  const securityDelay = 10; // 10 seconds
  const timeToExpire = getTimeToExpire(expirationTime);
  return (timeToExpire - securityDelay) < 0;
}
