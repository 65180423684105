import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { listEnrollments, deleteEnrollment, createEnrollment } from 'src/services/requests/enrollments';
import { getUserInfo, searchUser } from 'src/services/requests/user';
import { CsvLearner, EnrollmentsState, Learner } from 'src/types/Enrollments';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { EnrollmentsListResponse } from 'src/types/requests/Enrollments';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import type { RootState } from '../store';

export const fetchAssignedLearners = createAsyncThunk(
  'enrollments/fetchAssignedLearners',
  async (_, { rejectWithValue, getState }) => {
    const { auth, enrollments } = getState() as RootState;

    const accessToken = auth.tokens?.accessToken;
    if (!accessToken) {
      return rejectWithValue('fetchAssignedLearners: Not logged-in');
    }

    const { trainingProgramId } = enrollments;
    if (!trainingProgramId) {
      return rejectWithValue('fetchAssignedLearners: No training program ID in store');
    }

    type EnrolmentsResponse = FlowReturn<typeof listEnrollments>;
    const enrollmentsList: EnrolmentsResponse = await listEnrollments(accessToken, { trainingProgramId });

    if (!enrollmentsList?.items) {
      return rejectWithValue('fetchAssignedLearners: Enrollments list not found');
    }

    const assignedLearners = await Promise.all(enrollmentsList.items.map(async (enrollment) => {
      type UserInfoResponse = FlowReturn<typeof getUserInfo>;
      const userInfo: UserInfoResponse = await getUserInfo(String(enrollment.userId), accessToken);
      return {
        enrollmentId: enrollment.id,
        userId: userInfo?.userId,
        email: userInfo?.username,
        name: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
    }));

    return assignedLearners.filter((assignedLearner) => assignedLearner.userId);
  },
);

export const fetchAssignedLearnersCaseHandlers: ThunkCaseHandlers<EnrollmentsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.assignedLearners = [];
    state.modalLoading = true;
  },
  handleFulfilled: (state, { payload }: PayloadAction<Learner[]>) => {
    state.status = Status.SUCCEEDED;
    state.assignedLearners = payload;
    state.modalLoading = false;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
    state.modalLoading = false;
  },
};

export const deleteAssignedLearner = createAsyncThunk(
  'enrollments/deleteAssignedLearner',
  async (
    { enrollmentId }: { enrollmentId: string },
    { rejectWithValue, getState },
  ) => {
    const { auth } = getState() as RootState;
    const accessToken = auth.tokens?.accessToken;

    if (!accessToken) {
      return rejectWithValue('deleteAssignedLearner: Not logged-in');
    }

    type EnrolmentsResponse = FlowReturn<typeof deleteEnrollment>;
    const responseStatus: EnrolmentsResponse = await deleteEnrollment(enrollmentId, accessToken);

    if (responseStatus !== 204) {
      return rejectWithValue(`deleteAssignedLearner: Delete operation failed for enrollment ${enrollmentId}`);
    }

    return enrollmentId;
  },
);

export const deleteAssignedLearnerCaseHandlers: ThunkCaseHandlers<EnrollmentsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<string>) => {
    state.assignedLearners = state.assignedLearners.filter(
      (learner) => learner.enrollmentId !== payload,
    );
    state.learnersEnrolled = state.learnersEnrolled.filter(
      (learner) => learner.enrollmentId !== payload,
    );
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
  },
};

export const enrollLearners = createAsyncThunk(
  'enrollments/enrollLearners',
  async (
    learners: CsvLearner[],
    { rejectWithValue, getState },
  ) => {
    const { auth, enrollments, user } = getState() as RootState;

    const accessToken = auth.tokens?.accessToken;
    if (!accessToken) {
      return rejectWithValue('enrollLearners: Not logged-in');
    }

    const { trainingProgramId } = enrollments;
    if (!trainingProgramId) {
      return rejectWithValue('enrollLearners: No training program ID in store');
    }

    // For now, a having the logged user with multiple different organization IDs is 
    // considered an edge case that we won't handle, so taking the first one is enough
    const organizationId = user.organizationIds[0];

    type EnrolmentsResponse = FlowReturn<typeof listEnrollments>;
    const enrollmentsList: EnrolmentsResponse = await listEnrollments(accessToken, { trainingProgramId });

    const enrolledLearners = await Promise.all(learners.map(async (learner: CsvLearner) => {
      type SearchUserResponse = FlowReturn<typeof searchUser>;
      const currentUser: SearchUserResponse = await searchUser(learner.email, accessToken);

      if (!currentUser || !currentUser.userId) {
        return {
          enrollmentId: '',
          email: learner.email,
        };
      }

      type UserInfoResponse = FlowReturn<typeof getUserInfo>;
      const userInfo: UserInfoResponse = await getUserInfo(String(currentUser.userId), accessToken);

      if (!userInfo || !userInfo.userId) {
        return {
          enrollmentId: '',
          email: learner.email,
        };
      }

      if (!enrollmentsList) return rejectWithValue('enrollLearners: enrollmentsList fetch fail');
      const alreadyEnrolled = enrollmentsList.items?.find((enrollment) => enrollment.userId === userInfo.userId);

      if (alreadyEnrolled) {
        return {
          enrollmentId: alreadyEnrolled.id,
          email: userInfo.username,
          name: `${userInfo.firstName} ${userInfo.lastName}`,
          alreadyEnrolled: true,
        };
      }

      type CreateEnrollmentResponse = FlowReturn<typeof createEnrollment>;
      const enrollment: CreateEnrollmentResponse = await createEnrollment({
        accessToken,
        userId: userInfo.userId,
        trainingProgramId,
        organizationId,
        type: 'MANDATORY',
        dueAt: '',
      });

      return {
        enrollmentId: enrollment.id,
        email: userInfo.username,
        name: `${userInfo.firstName} ${userInfo.lastName}`,
      };
    }));

    return enrolledLearners;
  },
);

export const enrollLearnersCaseHandlers: ThunkCaseHandlers<EnrollmentsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.modalLoading = true;
  },
  handleFulfilled: (state, { payload }: PayloadAction<Learner[]>) => {
    state.status = Status.SUCCEEDED;
    state.learnersEnrolled = payload;
    state.modalLoading = false;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
    state.modalLoading = false;
  },
};

export const fetchEnrollmentsByUser = createAsyncThunk(
  'enrollments/fetchEnrollmentsByUser',
  async (_, { rejectWithValue, getState }) => {
    const { auth, user } = getState() as RootState;

    const accessToken = auth.tokens?.accessToken;
    if (!accessToken) {
      return rejectWithValue('fetchEnrollmentsByUser: AcessToken not found');
    }

    const userId = user.user?.id;
    if (!userId) return rejectWithValue('fetchEnrollmentsByUser: User id not found');

    type EnrolmentsResponse = FlowReturn<typeof listEnrollments>;

    try {
      const response: EnrolmentsResponse = await listEnrollments(accessToken, { userId });

      if (!response) return rejectWithValue('fetchEnrollmentsByUser: Could not fetch the enrolled programs');

      return response;
    } catch (err: any) {
      console.error(err.message);
    }
  },
);

export const fetchEnrollmentsByUserCaseHandlers: ThunkCaseHandlers<EnrollmentsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<EnrollmentsListResponse>) => {
    state.status = Status.SUCCEEDED;
    state.enrolledList = payload.items;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
  },
};
