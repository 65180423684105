import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import {
  createImage, createImageCaseHandlers, fetchImageUrl, fetchImageUrlCaseHandlers,
} from './thunks';
import { ImageState } from './types';

const initialState: ImageState = {
  status: Status.IDLE,
  showApiErrDialog: false,
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setShowApiErrDialog: (state, action) => {
      state.showApiErrDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // FetchImage
      .addCase(fetchImageUrl.rejected, fetchImageUrlCaseHandlers.handleRejected)

      // CreateImage
      .addCase(createImage.rejected, createImageCaseHandlers.handleRejected)
      .addCase(createImage.fulfilled, createImageCaseHandlers.handleFulfilled)
      .addCase(createImage.pending, createImageCaseHandlers.handlePending);
  },
});

export const { setShowApiErrDialog } = imageSlice.actions;

export default imageSlice.reducer;
