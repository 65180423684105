import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { searchTrackings as searchTrackingsApi } from 'src/services/requests/tracking';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { TrackingResponse } from 'src/types/requests/tracking';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { TrackingSearchRequest } from 'src/types/validators/TrackingSearchRequest';

import { TrackingSearchState } from './types';
import type { RootState } from '../store';

export const searchTrackings = createAsyncThunk(
  'trackings/search',
  async (
    { trainingProgramIds }: TrackingSearchRequest,
    { rejectWithValue, getState },
  ) => {
    const { auth } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('Trackings search: User is not logged in');
    }

    type Response = FlowReturn<typeof searchTrackingsApi>;
    try {
      const response: Response = await searchTrackingsApi(auth.tokens.accessToken, { trainingProgramIds });

      if (!response || !Array.isArray(response.items)) {
        return rejectWithValue('Trackings search: Failed trying to search for trackings');
      }

      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const searchCaseHandlers: ThunkCaseHandlers<TrackingSearchState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.trackings = [];
  },
  handleFulfilled: (state, action: PayloadAction<{ items: TrackingResponse[] }>) => {
    state.trackings = action.payload.items;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.showApiErrDialog = true;
    console.error(action.payload);
  },
};
