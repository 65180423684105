export enum LearningTypes {
  ASSESSMENT = 'ASSESSMENT',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  INTERACTIVE = 'INTERACTIVE',
  PICTURE = 'PICTURE',
  TRAINING = 'TRAINING',
  VIDEO = 'VIDEO',
  WEBSITE = 'WEBSITE',
}
