import { ParsedPlatformData, UserMapping } from 'src/types/requests/User';
import { Status } from 'src/types/Status';

export interface UserModel {
  id?: number,
  firstName?: string,
  lastName?: string,
  email?: string,
  avatarUrl?: string,
  roles: string[],
  activeRole: string,
}

export interface UserState {
  status: Status
  user?: UserModel
  organizationIds: number[]
  userMappings?: UserMapping[],
  userMappingId?: number,
  platformData?: ParsedPlatformData;
}

export enum PlatformRoles {
  LEARNER = 'learner',
  ADMIN = 'admin',
}
