import { generateApiUrl, TP_PATH_PATTERNS } from 'src/constants/api';
import { LearningObjectResponse } from 'src/types/requests/LearningObjects';
import { TrainingProgramStep, TrainingProgramStepResponse } from 'src/types/requests/TrainingPrograms';
import { getValidationErrors } from 'src/types/validators/ajv';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

type TrainingProgramStepRequest = {
  title: string;
  description?: string;
  estimatedDuration?: number;
  learningObjects?: string[];
};

export async function postTrainingProgramStep(
  stepData: TrainingProgramStepRequest,
  programId: string,
  accessToken: string,
): Promise<TrainingProgramStep | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.PROGRAM_STEPS, { programId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(stepData),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`Step post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Step post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Step post request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function deleteTrainingProgramStep(
  programId: string,
  stepId: string,
  accessToken: string,
): Promise<boolean> {
  const route = generateApiUrl(TP_PATH_PATTERNS.STEP, { programId, stepId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 204) {
    console.error(`Step delete request failed with status ${response.status}`, response);

    return false;
  }

  if (response) {
    return true;
  }

  console.error('Step delete response structure is invalid. Probably API has changed', getValidationErrors(), response);
  return false;
}

export async function putTrainingProgramStep(
  programId: string,
  stepId: string,
  accessToken: string,
  stepData: TrainingProgramStepRequest,
): Promise<TrainingProgramStep | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.STEP, { programId, stepId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const body = {
    title: stepData.title,
    description: stepData.description,
    learningObjects: stepData.learningObjects || [],
  };

  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Step put request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Step put request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Step put request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function getTrainingProgramStep(
  programId: string,
  stepId: string,
  accessToken: string,
): Promise<TrainingProgramStepResponse | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.STEP, { programId, stepId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'GET',
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Step get request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Step get request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Step get request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function postLearningObjectToStep(
  programId: string,
  stepId: string,
  learningObjectId: string,
  accessToken: string,
): Promise<LearningObjectResponse | undefined> {
  const route = generateApiUrl(TP_PATH_PATTERNS.STEP_LOS, { programId, stepId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const body = {
    learningObject: {
      id: learningObjectId,
    },
    isMandatory: true,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (response.status !== 201) {
    if (validateResponseErrorsList(json)) {
      console.error(`LO to Step post request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`LO to Step post request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`LO to Step post request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json;
}

export async function deleteLearningObjectFromStep(
  programId: string,
  stepId: string,
  learningObjectId: string,
  accessToken: string,
): Promise<boolean> {
  const route = generateApiUrl(TP_PATH_PATTERNS.LO, { programId, stepId, learningObjectId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
  };

  const response = await fetch(route, options);

  if (response.status !== 204) {
    console.error(`LO of Step delete request failed with status ${response.status}`, response);

    return false;
  }

  if (response) {
    return true;
  }

  console.error('LO of Step delete response structure is invalid. Probably API has changed', getValidationErrors(), response);
  return false;
}
