import { createSlice } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import { fetchOrganization, fetchOrganizationCaseHandlers } from './thunks';
import { OrganizationState } from './types';

const initialState: OrganizationState = {
  status: Status.IDLE,
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // fetch Organization
      .addCase(fetchOrganization.pending, fetchOrganizationCaseHandlers.handlePending)
      .addCase(fetchOrganization.fulfilled, fetchOrganizationCaseHandlers.handleFulfilled)
      .addCase(fetchOrganization.rejected, fetchOrganizationCaseHandlers.handleRejected);
  },
});

export default organizationsSlice.reducer;
