import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import {
  fetchStep,
  fetchStepCaseHandlers,
  createStep,
  createStepCaseHandlers,
  updateStep,
  updateStepCaseHandlers,
  removeStep,
  removeStepCaseHandlers,
} from './step/thunks';
import {
  fetchProgram,
  fetchProgramCaseHandlers,
  createProgram,
  createProgramCaseHandlers,
  updateProgram,
  updateProgramCaseHandlers,
} from './thunks';
import { TrainingProgramState } from './types';

const initialState: TrainingProgramState = {

  // statuses
  status: Status.IDLE,
  stepStatus: Status.IDLE,
  learningContentStatus: Status.IDLE,

  // training program
  currentProgram: null,
  programFetched: null,
  showApiErrDialog: false,
  firstStepCreated: false,
};

const trainingProgramSlice = createSlice({
  name: 'trainingProgram',
  initialState,
  reducers: {
    setCurrentProgram: (state, action) => {
      state.currentProgram = action.payload;
    },
    setShowApiErrDialog: (state, action) => {
      state.showApiErrDialog = action.payload;
    },
    clearCurrentProgram: (state) => {
      state.currentProgram = null;
    },
    clearProgramFetched: (state) => {
      state.programFetched = null;
    },
    moveStep: (state, { payload }: PayloadAction<{ stepId: string, newIndex: number }>) => {
      const { stepId, newIndex } = payload;
      try {
        if (!state.currentProgram || !state.currentProgram.id) {
          throw new Error('Current program does not exisits or is yet to be created');
        }

        const stepToMove = state.currentProgram.steps?.find((step) => stepId === step.id);
        if (!stepToMove) {
          throw new Error('No step exists with given id');
        }

        const newSteps = state.currentProgram.steps?.filter((step) => step.id !== stepToMove.id);
        newSteps?.splice(newIndex, 0, stepToMove);

        state.currentProgram.steps = newSteps;
      } catch (err: any) {
        console.error(err.message);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fechProgram
      .addCase(fetchProgram.pending, fetchProgramCaseHandlers.handlePending)
      .addCase(fetchProgram.fulfilled, fetchProgramCaseHandlers.handleFulfilled)
      .addCase(fetchProgram.rejected, fetchProgramCaseHandlers.handleRejected)

      // createProgram
      .addCase(createProgram.pending, createProgramCaseHandlers.handlePending)
      .addCase(createProgram.fulfilled, createProgramCaseHandlers.handleFulfilled)
      .addCase(createProgram.rejected, createProgramCaseHandlers.handleRejected)

      // updateProgram
      .addCase(updateProgram.pending, updateProgramCaseHandlers.handlePending)
      .addCase(updateProgram.fulfilled, updateProgramCaseHandlers.handleFulfilled)
      .addCase(updateProgram.rejected, updateProgramCaseHandlers.handleRejected)

      // fetchStep
      .addCase(fetchStep.pending, fetchStepCaseHandlers.handlePending)
      .addCase(fetchStep.fulfilled, fetchStepCaseHandlers.handleFulfilled)
      .addCase(fetchStep.rejected, fetchStepCaseHandlers.handleRejected)

      // createStep
      .addCase(createStep.pending, createStepCaseHandlers.handlePending)
      .addCase(createStep.fulfilled, createStepCaseHandlers.handleFulfilled)
      .addCase(createStep.rejected, createStepCaseHandlers.handleRejected)

      // updateStep
      .addCase(updateStep.pending, updateStepCaseHandlers.handlePending)
      .addCase(updateStep.fulfilled, updateStepCaseHandlers.handleFulfilled)
      .addCase(updateStep.rejected, updateStepCaseHandlers.handleRejected)

      // removeStep
      .addCase(removeStep.pending, removeStepCaseHandlers.handlePending)
      .addCase(removeStep.fulfilled, removeStepCaseHandlers.handleFulfilled)
      .addCase(removeStep.rejected, removeStepCaseHandlers.handleRejected);
  },
});

export const {
  setCurrentProgram,
  setShowApiErrDialog,
  clearCurrentProgram,
  clearProgramFetched,
  moveStep,
} = trainingProgramSlice.actions;

export default trainingProgramSlice.reducer;
