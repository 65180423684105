import { Box, CircularProgress, Typography } from '@mui/material';

import './ConnectLoading.scss';

interface ConnectLoadingProps {
  loadingMsg?: string
  dataTestid?: string
}

function ConnectLoading({ loadingMsg, dataTestid }: ConnectLoadingProps) {
  return (
    <Box
      className="ConnectLoading"
      data-testid={dataTestid}
    >
      <CircularProgress
        disableShrink
        size={70}
        thickness={4}
        color="primary"
      />

      {
        loadingMsg && (
        <Typography className="ConnectLoading-text">
          {loadingMsg}
        </Typography>
        )
      }
    </Box>
  );
}

export default ConnectLoading;
