import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { KEY_TOKENS } from 'src/constants/localStorage';
import { decodeToken } from 'src/helpers/jwt';
import { RootState } from 'src/redux/store';
import {
  changePassword, getTokens, refreshTokens, recoverPassword, userLogin,
} from 'src/services/requests/auth';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { OpenIdConnectTokenResponse } from 'src/types/requests/OpenIdConnectTokenResponse';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';
import { UserLoginResponse } from 'src/types/validators/UserLoginResponse';

import type { AuthState } from './types';

interface AuthenticateParams {
  authCode?: string,
  redirectUri?: string,
  username?: string,
  password?: string
}

export const authenticate = createAsyncThunk(
  'auth/authenticate',
  async ({
    authCode, redirectUri, username, password,
  }: AuthenticateParams, { rejectWithValue }) => {
    type ResponseWithCredentials = FlowReturn<typeof userLogin>;
    type ResponseWithToken = FlowReturn<typeof getTokens>;

    let response: ResponseWithCredentials | ResponseWithToken | null = null;

    // Authentication with credentials
    if (username && password) {
      response = await userLogin(username, password);
    }

    // Authentication by redirect
    if (authCode && redirectUri) {
      response = await getTokens(authCode, redirectUri) as ResponseWithToken;
    }

    if (!response?.access_token) {
      return rejectWithValue('Unable to fetch tokens. "access_token" field not found in response.');
    }

    return response;
  },
);

export const authenticateCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<OpenIdConnectTokenResponse | UserLoginResponse>) => {
    state.tokens = {
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token,
      expiresIn: payload.expires_in,
      tokenType: payload.token_type,
    };
    localStorage.setItem(KEY_TOKENS, JSON.stringify(payload));
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    console.error(action.payload);
  },
};

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async (_, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;

    if (!auth.tokens) {
      return rejectWithValue('Unable to refresh token. Tokens not found.');
    }

    const { accessToken, refreshToken } = auth.tokens;

    if (!accessToken || !refreshToken) {
      return rejectWithValue('Unable to refresh token. Access and refresh tokens not found.');
    }

    const decoded = decodeToken(accessToken);

    if (!decoded.iss) {
      return rejectWithValue(`Unable to refresh token. "iss" field not found in decoded token, ${JSON.stringify(decoded)}`);
    }

    type Response = FlowReturn<typeof refreshTokens>;
    const response: Response = await refreshTokens(decoded.iss, refreshToken);

    if (!response) {
      return rejectWithValue('Unable to refresh token. The refresh tokens response is not received');
    }

    return response;
  },
);

export const refreshAccessTokenCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: () => {
    // not setting status to loading here for the ProtectedRoute not to
    // display the loading indicator and re-render on refresh token
  },
  handleFulfilled: (state, { payload }: PayloadAction<OpenIdConnectTokenResponse>) => {
    state.status = Status.SUCCEEDED;
    state.tokens = {
      accessToken: payload.access_token,
      refreshToken: payload.refresh_token,
      expiresIn: payload.expires_in,
      tokenType: payload.token_type,
    };

    localStorage.setItem(KEY_TOKENS, JSON.stringify(payload));
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.tokens = undefined;
    console.error(action.payload);
  },
};

export const sendRecoverPasswordEmail = createAsyncThunk(
  'forgotPassword',
  async (
    userEmail: string,
  ) => {
    type Response = FlowReturn<typeof recoverPassword>;
    const response: Response = await recoverPassword(userEmail);

    return response;
  },
);

export const sendRecoverPasswordEmailCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
    state.tokens = undefined;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.tokens = undefined;
    console.error(action.payload);
  },
};

interface ResetPasswordParams {
  newPassword: string,
  code: string,
}

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (
    { code, newPassword }: ResetPasswordParams,
  ) => {
    type Response = FlowReturn<typeof changePassword>;
    const response: Response = await changePassword(code, newPassword);

    return response;
  },
);

export const resetPasswordCaseHandlers: ThunkCaseHandlers<AuthState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state) => {
    state.status = Status.SUCCEEDED;
    state.tokens = undefined;
  },
  handleRejected: (state, action) => {
    state.status = Status.FAILED;
    state.tokens = undefined;
    console.error(action.payload);
  },
};
