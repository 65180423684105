import { ORGANIZATION_PATH_PATTERNS, generateApiUrl } from 'src/constants/api';
import { SingleOrganizationResponse } from 'src/types/requests/Organizations';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';

export async function getSingleOrganization(organizationId: string, accessToken: string) {
  const route = generateApiUrl(ORGANIZATION_PATH_PATTERNS.SINGLE_ORGANIZATION, { organizationId });

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'GET',
    headers,
  };
  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Organization request failed with status ${response.status}`, json);
    }

    return json as SingleOrganizationResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Organization request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Organization request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Organization request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json as SingleOrganizationResponse;
}
