import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getProgram } from 'src/services/requests/trainingProgram';
import { getTrainingProgramList } from 'src/services/requests/trainingProgramList';
import { ThunkCaseHandlers } from 'src/types/Redux';
import { TrainingProgramResponse } from 'src/types/requests/TrainingPrograms';
import { Status } from 'src/types/Status';
import { FlowReturn } from 'src/types/utils';

import { TrainingProgramListState } from './types';
import type { RootState } from '../store';

export const fetchTrainingProgramList = createAsyncThunk(
  'trainingProgramList/fetchTrainingProgramList',
  async (_params, { rejectWithValue, getState }) => {
    const { auth, user, trainingProgramList } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('Training program list fetch: User is not logged in');
    }

    const offset = (trainingProgramList.currentPage - 1) * trainingProgramList.pageSize;
    const organizationId = user.organizationIds[0];

    const queryParams = { size: trainingProgramList.pageSize, offset, organizationId };

    type Response = FlowReturn<typeof getTrainingProgramList>;
    const response: Response = await getTrainingProgramList(
      auth.tokens.accessToken,
      queryParams,
    );

    if (!response) {
      return rejectWithValue('Training program list fetch: Could not fetch program list');
    }

    const { items, count } = response;
    const totalResultCount = count || 0;
    const pageCount = Math.ceil(totalResultCount / trainingProgramList.pageSize);

    return { items, totalResultCount, pageCount };
  },
);

export const fetchTrainingProgramListCaseHandlers: ThunkCaseHandlers<TrainingProgramListState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.trainingPrograms = [];
  },
  handleFulfilled: (state, { payload }: PayloadAction<{
    items: TrainingProgramResponse[],
    totalResultCount: number,
    pageCount: number,
  }>) => {
    state.trainingPrograms = payload.items;
    state.totalResultCount = payload.totalResultCount;
    state.pageCount = payload.pageCount;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }: PayloadAction<string>) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};

// Fetch Enrolled TP for Learners
export const fetchEnrolledTrainingPrograms = createAsyncThunk(
  'trainingProgramList/fetchEnrolledTrainingPrograms',
  async (_params, { rejectWithValue, getState }) => {
    const { auth, enrollments } = getState() as RootState;

    if (!auth.tokens || !auth.tokens.accessToken) {
      return rejectWithValue('fetchEnrolledTrainingPrograms: User is not logged in');
    }

    if (!enrollments.enrolledList) return rejectWithValue('fetchEnrolledTrainingPrograms: Enrolled list not found');

    const trainingPrograms = await Promise.all(
      enrollments.enrolledList?.map((item) => getProgram(item.trainingProgramId, auth.tokens?.accessToken!)),
    );

    if (!trainingPrograms) {
      return rejectWithValue('fetchEnrolledTrainingPrograms: Could not fetch program list');
    }

    // Sometimes we receive an program on the enrolled that has being deleted, I remove here to not have undefined in the array
    return trainingPrograms.filter((program) => !!program);
  },
);

export const fetchEnrolledTrainingProgramsCaseHandlers: ThunkCaseHandlers<TrainingProgramListState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
    state.trainingPrograms = [];
  },
  handleFulfilled: (state, { payload }: PayloadAction<TrainingProgramResponse[]>) => {
    state.trainingPrograms = payload;
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state, { payload }: PayloadAction<string>) => {
    state.status = Status.FAILED;
    console.error(payload);
  },
};
