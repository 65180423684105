import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { switchActiveRole } from 'src/redux/user/slice';

import MainHeaderMobileMenu from './MainHeaderMobileMenu/MainHeaderMobileMenu';
import MainHeaderProfileIcon from './MainHeaderProfileIcon/MainHeaderProfileIcon';
import MainHeaderProfileMenu from './MainHeaderProfileMenu/MainHeaderProfileMenu';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';

import './MainHeader.scss';

// eslint-disable-next-line max-len
const logo = 'https://www.wiley.com/medias/Wiley-Logo-Black.jpg?context=bWFzdGVyfGltYWdlc3wxMTc0NnxpbWFnZS9qcGVnfHN5cy1tYXN0ZXIvaW1hZ2VzL2g5OS9oYjIvODg4MzY3ODk2OTg4Ni9XaWxleSBMb2dvIEJsYWNrLmpwZ3w3ZDIwZGE3N2M0OTdhOTgwMWIwZjNlODhkN2RlY2JmN2Q5NGQyNDdhZWE4NTZmOGZlODkxZjYxYmE0NjM0ZGM5';

function MainHeader() {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const loggedUser = user.user;

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<null | HTMLElement>(null);

  const profileMenuId = 'profile-menu';
  const mobileMenuId = 'mobile-menu';

  const handleProfileMenuOpen = (event: React.ChangeEvent<any>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSwitchActiveRole = (roleName: string) => {
    dispatch(switchActiveRole(roleName));
    handleProfileMenuClose();
  };

  return (
    <>
      <AppBar
        className="MainHeader"
        position="sticky"
        sx={{ backgroundColor: theme.palette.secondary.dark }}
      >
        <MaxWidthContainer>
          <Toolbar
            className="MainHeader-toolbar"
            disableGutters
          >
            <Box className="MainHeader-logoWrapper">
              {logo && (
                <img
                  className="MainHeader-logo"
                  height={60}
                  width={136}
                  alt="Client Logo"
                  src={logo}
                />
              )}
            </Box>

            {loggedUser && (
              <Box className="MainHeader-profileIconWrapper">
                <MainHeaderProfileIcon
                  profileMenuId={profileMenuId}
                  handleProfileMenuOpen={handleProfileMenuOpen}
                  loggedUser={loggedUser}
                />
              </Box>
            )}
          </Toolbar>
        </MaxWidthContainer>

        <Box
          className="MainHeader-bottomDivider"
          sx={{
            height: theme.spacing(0.5),
            backgroundColor: theme.palette.secondary.main,
          }}
        />
      </AppBar>

      {/* Display only when user is authenticated */
        loggedUser && (
          <>
            <MainHeaderMobileMenu
              menuId={mobileMenuId}
              anchorEl={mobileMenuAnchorEl}
              handleMenuClose={handleMobileMenuClose}
              navOptions={[]}
            />

            <MainHeaderProfileMenu
              menuId={profileMenuId}
              anchorEl={profileMenuAnchorEl}
              handleMenuClose={handleProfileMenuClose}
              loggedUser={loggedUser}
              switchActiveRole={handleSwitchActiveRole}
              displaySwitchExperience={!!user.platformData?.platformDataUrl}
            />
          </>
        )
      }
    </>
  );
}

export default MainHeader;
