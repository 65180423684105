/* eslint-disable import/order */

import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from 'src/types/Status';

import type { RootState } from '../store';
import type { ReportsState } from './types';
import { decodeToken, isTokenExpired } from 'src/helpers/jwt';
import { FlowReturn } from 'src/types/utils';
import { getPowerBiAccessToReport } from '../../services/requests/reports';
import { ThunkCaseHandlers } from 'src/types/Redux';

export const getPowerBiAccess = createAsyncThunk(
  'reports/getPowerBiAccess',
  async (
    _params,
    { rejectWithValue, getState },
  ) => {
    const currentState = getState() as RootState;

    if (!currentState.auth.tokens || !currentState.auth.tokens.accessToken) {
      return rejectWithValue('getPowerBiAccess: No tokens set in store.');
    }

    const accessToken = currentState.auth.tokens?.accessToken;
    const decoded = decodeToken(accessToken);

    if (!decoded.exp) {
      return rejectWithValue('getPowerBiAccess: "exp" field not found in decoded token');
    }

    if (isTokenExpired(decoded.exp)) {
      return rejectWithValue('getPowerBiAccess: Unable to refresh token. Token is expired');
    }

    if (!currentState.user.user || !currentState.user.user.id) {
      return rejectWithValue('getPowerBiAccess: No user set in store.');
    }

    if (!currentState.reports.currentReport) {
      return rejectWithValue('getPowerBiAccess: No report set in store.');
    }

    const userId = currentState.user.user.id;
    const { groupId, reportId, reportSectionId } = currentState.reports.currentReport.links['en-US'];

    type Response = FlowReturn<typeof getPowerBiAccessToReport>;
    const response: Response = await getPowerBiAccessToReport({
      groupId,
      reportId,
      reportSectionId,
      accessToken,
      userId,
    });

    if (!response) {
      return rejectWithValue('getPowerBiAccess: No response was received.');
    }

    return response;
  },
);

export const getPowerBiAccessCaseHandlers: ThunkCaseHandlers<ReportsState> = {
  handlePending: (state) => {
    state.status = Status.LOADING;
  },
  handleFulfilled: (state, { payload }: PayloadAction<{
    access_token: string,
    embed_url: string,
  }>) => {
    state.reportAccessConfig = {
      accessToken: payload.access_token,
      embedUrl: payload.embed_url,
    };
    state.status = Status.SUCCEEDED;
  },
  handleRejected: (state) => {
    state.status = Status.FAILED;
  },
};
