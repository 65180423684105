import { useState } from 'react';

import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AlertDialog from 'src/components/@common/AlertDialog/AlertDialog';
import { ROUTES, reverseRoute } from 'src/config/routes/routes';

interface Props {
  text: string,
  onSwitch: () => void,
}

function SwitchExperienceMenuItem({
  text, onSwitch,
}: Props) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const handleClickOpen = () => setOpenAlertDialog(true);
  const handleCancel = () => setOpenAlertDialog(false);

  // TODO: Execution of asynchronous functions in the component should not be, we'll need to be back to refact this block of code
  const handleConfirm = async () => {
    setOpenAlertDialog(false);
    onSwitch();
    navigate(reverseRoute(ROUTES.logout));
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <SettingsBackupRestore fontSize="small" />
        </ListItemIcon>

        <ListItemText>{text}</ListItemText>
      </MenuItem>

      {openAlertDialog && (
        <AlertDialog
          title={t('switchExperience.title', { productCKLS: 'CKLS' })}
          description={t('switchExperience.description', { productCKLS: 'CKLS' })}
          cancelButtonText={t('switchExperience.cancel')}
          onCancelPress={handleCancel}
          confirmButtonText={t('switchExperience.confirm')}
          onConfirmPress={handleConfirm}
          data-testid="SwitchExperienceMenuItem-Dialog-test-id"
        />
      )}
    </>
  );
}

export default SwitchExperienceMenuItem;
