import { Error } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import './ErrorPage.scss';

export type ErrorPageProps = {
  message?: string,
  linkText?: string,
  linkPath?: string,
};

const ErrorPage = ({ message, linkText, linkPath }: ErrorPageProps) => {
  const location: {
    state?: {
      messageText: string,
      buttonText: string,
      buttonUri: string,
    }
  } = useLocation();
  const { t } = useTranslation('error');

  const theme = useTheme();
  const { grey } = theme.custom.palette;

  return (
    <Box
      className="ErrorPage"
      data-testid="ErrorPage"
    >
      <Error
        className="ErrorPage-icon"
        sx={{ color: grey[400] }}
      />

      <Typography className="ErrorPage-text">
        {
          location.state?.messageText
          || message
          || t('general')
        }
      </Typography>

      {location.state?.buttonText && location.state?.buttonUri && (
        <Link
          underline="none"
          className="ErrorPage-link"
          href={location.state.buttonUri}
        >
          {location.state.buttonText}
        </Link>
      )}

      {linkText && linkPath && (
        <Link
          underline="none"
          className="ErrorPage-link"
          component={RouterLink}
          to={linkPath}
        >
          {linkText}
        </Link>
      )}
    </Box>
  );
};

export default ErrorPage;
